.category {
  &-wrapper {
    padding: 6rem;
    min-height: 50vh;
    position: relative;

    @include media-breakpoint-down(lg) {
      padding: 3rem;
    }
  }

  &-sub-title {
    font-size: 2.4rem;

    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
  }
}

.category-filter-mobile {
  position: fixed;
  display: flex;
  bottom: 2%;
  left: 50%;
  font-size: 2rem;
  transform: translateX(-50%);
  background-color: var(--color-primary-blue);
  color: white;
  z-index: 10;
  padding: 1.5rem 2.5rem;
  border-radius: 4rem;
  cursor: pointer;
}

.category-filter-item {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}

.category-filter-button-container {
  position: sticky;
  background-color: white;
  bottom: 0;
}


.category-show-all-filter {
  text-align: right;
  font-size: 1.5rem;
  color: var(--color-primary-blue);

  a {
    text-decoration: none;
  }
}

.filter-container {
  .filter-box:nth-child(1) {
    margin-top: 0px !important;
  }
}

.pagination-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $bp-largest) {
    .show-results {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
