.collapsible {
  margin: 1.8rem 0;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif !important;

  &__title {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }

  &__description {
    background-color: rgba(6, 107, 251, 0.05);
    font-size: 2rem;
    font-weight: 400;
    padding: 2rem;
    margin-top: 2rem;
    line-height: 4rem;

    iframe {
      max-width: 100% !important;
    }

    figure {
      max-width: 100%;

      img {
        max-width: 100%;
      }

      table {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
    }
  }
  &__download {
    float: right;
    text-align: right;
    color: #066bfb;
    text-indent: 50px;
  }
}
