.landing-page-container {
  overflow-x: hidden;
}

.hero-wrapper {
  padding: 6rem;
  max-height: calc(100vh - 10.4rem);
  font-family: "Titillium Web";

  @include media-breakpoint-down(lg) {
    padding: 3rem;
    max-height: unset;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
    max-height: unset;
  }

  .hero-sub {
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    color: var(--color-primary-blue);

    @include media-breakpoint-down(md) {
      font-size: 2rem;
      font-weight: normal;
    }
  }

  .hero-text {
    font-style: normal;
    font-weight: 700;
    font-size: 4.6rem;
    color: var(--color-primary-blue-hover);
    padding-right: 5rem;

    @include media-breakpoint-down(lg) {
      width: 80%;
      padding: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      padding-right: 0;
      font-size: 3rem;
    }
  }

  .hero-button {
    background: var(--color-primary-blue);
    border-radius: 0.4rem;
    border: 0;
    height: 5.8rem;
    width: 16rem;
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #ffffff;
  }

  .hero-watch {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: var(--color-primary-blue);
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hero-image {
    position: relative;
    display: flex;
    justify-content: center;

    .hero-recorded-section {
      position: absolute;
      left: 0;
      bottom: 4rem;
      background: #ffffff;
      box-shadow: 0px 14px 34px rgb(172 172 172 / 25%);
      border-radius: 1.2rem;
      padding: 1.6rem 7.5rem;

      &-sub {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        color: #969696;
      }

      &-text {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        color: #000000;
      }

      &-image {
        width: 11rem;
        height: 11rem;
      }

      &-icon {
        position: absolute;
        top: -1rem;
        left: -1rem;
      }
    }

    .hero-course-section {
      position: absolute;
      right: 0;
      top: 10rem;
      background: #ffffff;
      box-shadow: 0px 14px 34px rgb(172 172 172 / 25%);
      border-radius: 1.2rem;
      padding: 1.5rem 6rem 1.5rem 2rem;
      display: flex;
      align-items: center;

      &-image {
        padding-right: 1rem;
      }

      &-sub {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 800;
        font-size: 2.2rem;
        color: #030229;
        opacity: 0.7;
      }

      &-text {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        opacity: 0.7;
      }
    }
  }
}
