.nav-wrapper {
  width: 100%;
  min-height: 10rem;
  box-shadow: -2px 2px 20px 1px #0000002e;
  padding: 1rem 5rem 1rem 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10rem;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: var(--z-index-root);

  @include media-breakpoint-down(lg) {
    box-shadow: none;
    min-height: unset;
    padding: 0.5rem 2.5rem 2.5rem;
    gap: 0;
    align-items: flex-end;
  }

  @include media-breakpoint-down(sm) {
    padding: 0.2rem 2.2rem 2.2rem;
  }

  &.search-bar-opened {
    @include media-breakpoint-down(lg) {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      align-items: center;
      gap: 2rem;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .menu-logo {
      @include media-breakpoint-down(lg) {
        flex-basis: unset;
      }

      .menu {
        margin-bottom: 0;
      }
    }

    .nav-logo {
      @include media-breakpoint-down(lg) {
        flex-basis: unset;
      }

      .logo {
        display: none;
      }
    }

    .nav-menu {
      &.nav-menu-left {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &.nav-menu-right {
        @include media-breakpoint-down(lg) {
          flex-grow: 1;
          flex-basis: unset;
          align-items: center;
        }
      }

      .nav-menu__search-bar {
        @include media-breakpoint-down(lg) {
          border: 1px solid rgba(228, 228, 228, 0.6);
          border-radius: 5px;
          padding: 1.1rem 2rem;
          width: 100%;
        }

        .search-field {
          margin-left: 2rem;
          display: flex !important;
        }

        .search-logo {
          cursor: default;
        }
      }

      .close-logo {
        display: flex;
        cursor: pointer;
      }
    }
  }

  .search-field {
    border: none;
    outline: none;
  }

  .search-button {
    width: 150px;
    border: none;
    color: #fff;
    background: #0665ec;
    font-size: 14px;
    border-radius: 0 2px 2px 0;
  }
  .cart-bubble {
    border-radius: 32px;
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: #fff;
    position: absolute;
    border: solid #fff;
    background: #ff579c;
    top: -5px;
    right: -23px;

    @include media-breakpoint-down(lg) {
      padding: 0;
      font-size: 0.8rem;
      width: 20px;
      height: 20px;
      top: -2px;
      right: -7px;
    }
  }

  .menu-logo {
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      flex-basis: 25%;
    }

    .menu {
      margin-bottom: 0.4rem;
      width: 24px;

      @include media-breakpoint-down(lg) {
        max-height: 40px;
      }
    }
  }

  .nav-logo {
    max-width: 40%;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }

    .logo {
      display: flex;
      width: 100%;

      @include media-breakpoint-down(lg) {
        max-height: 40px;
      }
    }
  }

  .nav-menu {
    height: 100%;
    font-family: 'Titillium Web';
    display: flex;
    align-items: center;
    gap: 4rem;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      // width: auto;
      flex-grow: 0;
      gap: 2rem;
      align-items: flex-end;
    }

    &.nav-menu-left {
      flex-basis: 60%;

      @include media-breakpoint-down(lg) {
        flex-basis: 50%;
      }
    }

    &.nav-menu-right {
      flex-basis: 40%;

      @include media-breakpoint-down(lg) {
        flex-basis: 25%;
      }
    }

    &__search-bar {
      border: solid thin #0666f0;
      max-width: 436px;
      width: 100%;

      @include media-breakpoint-down(lg) {
        border: none;
        width: unset;
        max-width: unset;
      }

      .search-logo {
        font-size: 14px;

        @include media-breakpoint-down(lg) {
          cursor: pointer;
          font-size: 24px;
        }
      }

      .close-logo {
        display: none;
      }
    }

    &__regular {
      font-weight: 700;
      font-size: 1.8rem;
      color: var(--color-primary-blue);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
    }

    &__dropdown {
      font-weight: 400;
      font-size: 1.8rem;
      white-space: nowrap;
      color: var(--color-text-grey-dark);
      font-family: 'Titillium Web';
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1.5rem;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }

      img {
        transition: all 0.2s ease-in-out;
        transform: rotate(0);
        &.opened {
          transition: all 0.2s ease-in-out;
          transform: rotate(180deg);
        }
      }

      & img {
        padding-top: 0.1rem;
      }
    }

    &__search-bar {
      display: flex;
      position: relative;
      border-radius: 4px;
    }

    &__cart {
      position: relative;
      // background-color: aliceblue;
      @include media-breakpoint-down(lg) {
        // margin-bottom: 0.2rem;
      }

      .cart-logo {
        font-size: 28px;

        @include media-breakpoint-down(lg) {
          font-size: 24px;
        }
      }
    }
  }
}

.navbar-category-parent {
  position: relative;
  padding: 0 10px;
}

.navbar-category-child {
  position: absolute;
  background: #fff;
  font-family: 'Titillium Web';
  width: 350px;
  box-shadow: 1px 2px 5px -2px #d4d4d4;
  padding: 15px 0;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 5px;
  top: -2rem;
  display: none;
  left: 100%;
  cursor: default;

  @include media-breakpoint-down(xl) {
    width: 300px;
  }
}

.nav-child {
  // position: absolute;
  background: #fff;
  font-family: 'Titillium Web';
  width: 275px;
  box-shadow: 0px 7px 10px -1px #d4d4d4;
  padding: 15px 0;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 5px;

  p {
    transition: all 0.3s;
    font-size: 1.7rem;
  }

  .navbar-with-child {
    &___container {
      position: relative;
      padding: 0 15px;
      cursor: pointer;

      &:hover {
        .navbar-with-child___item {
          background: #cadaf1;
          color: var(--color-primary-blue);
          border-radius: 0.5rem;

          .chevron-image {
            background-image: url(/navbar/arrow_blue.svg);
          }
        }
      }
    }

    &___item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 8px;

      p {
        margin-bottom: 0;
      }

      .chevron-image {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/navbar/arrow_grey.svg);
      }
    }
  }

  .navbar-no-child {
    &___container {
      padding: 0 15px;
      cursor: default;
    }

    &___item {
      margin: 0;
      padding: 7px 8px;

      &:hover {
        cursor: pointer;
        background: #cadaf1;
        color: var(--color-primary-blue);
        border-radius: 0.5rem;
      }
    }
  }

  .navbar-all-item {
    &___container {
      padding: 0 15px;
      cursor: default;
    }

    &___item {
      border: 1px solid var(--color-primary-blue);
      border-radius: 3px;
      color: var(--color-primary-blue);
      padding: 7px 8px;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
        background: #cadaf1;
      }
    }
  }
}

// .navbar-category-parent:hover .navbar-category-child {
//   display: block;
// }

.navbar-category-child:hover {
  display: block;
}

.mobile-menu {
  display: flex;
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  transform: translateX(-100%);
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.6s ease-in-out;

  &-container {
    width: 75%;
    height: 100%;
    padding: 5rem 2rem;
    position: relative;
    transform: translateX(-100%);
    transition: all 0.2s ease-in-out;
    overflow: auto;
    background: white;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  &-content {
    // margin-bottom: 2rem;
  }

  &-close {
    margin-top: 5rem;
    margin-left: 1rem;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .close-logo {
      font-size: 30px;
    }
  }

  &__menu-title {
    display: flex;
    justify-content: space-between;
    font-size: 2.3rem;
    padding: 1rem;
    font-weight: 600;

    i {
      color: #969696;
    }
  }

  &__menu-container {
    i {
      font-size: 2.3rem;
      color: #969696;
    }

    .button-view-all {
      width: calc(100% - 2rem);
      font-size: 2.3rem;
      border: 1px solid #066bfb;
      border-radius: 5px;
      color: #066bfb;
      padding: 1rem;
      margin: 1rem;
      text-align: center;
    }

    // padding-top: 1rem;

    &.menu-opened {
      background-color: #f3f8ff;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 1rem;

      i {
        transition: all 0.2s ease-in-out;
        transform: rotate(180deg);
        color: #0666f0;
      }

      .mobile-menu {
        &__parent {
          background: rgba(6, 107, 251, 0.1);
          color: #066bfb;
        }
      }
    }
  }

  &__parent {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    color: #969696;
    font-weight: 600;
    padding: 1rem;
    // margin-top: 1rem;
  }

  &__child {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    color: #969696;
    color: black;
    font-weight: 600;
    padding: 1rem;
    // margin-top: 2rem;
  }

  .menu-opened {
    i {
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
    }
  }

  &.opened {
    transform: translateX(0);
    opacity: 1;

    .mobile-menu-container {
      transform: translateX(0);
    }
  }
}
