.not-found {
  &__wrapper {
    padding: 10rem 0;
    min-height: 60vh;
  }

  &__image-container {
    width: 300px;
    height: 300px;

    @include media-breakpoint-down(md) {
      width: 50%;
      height: 50%;
    }

    .illustration-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .notfound-image {
      width: 60%;
      height: 60%;
      object-fit: contain;
      top: -10%;
      right: -23%;
    }
  }

  &__description {
    text-align: center;

    .title {
      color: var(--color-danger);
      font-size: 3.5rem;
      font-weight: 700;
    }

    .subtitle {
      font-size: 2rem;
      color: var(--color-text-grey-dark);
    }
  }
}
