// General Setup
.card-horizontal { 
  display: grid;
  grid-template-columns: 47% 50%;
  grid-column-gap: 2.5rem;

  align-items: flex-start;
  border-bottom: 1px solid rgba(196, 196, 196, 1);

  margin: 3rem 0;
  padding-bottom: 1.5rem;

  @include media-breakpoint-down(lg) { 
    grid-template-columns: 47% 47%;
  }
}

.card-horizontal-2 {
  @extend .card-horizontal;
  grid-template-columns: 30% 65%;

  align-items: flex-start;
  border-bottom: none;
}

.card-floating {
  background-color: white;
  box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
  border-radius: 1.2rem;
  padding: 2rem 1.6rem 3.2rem;
}