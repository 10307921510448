.modal-description-checkout {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  height: 100%;

  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.8rem);
  z-index: var(--z-index-root);
}

.modal-description-container {
  width: 90%;
  display: flex;
  justify-content: center;

  &.is-full-height {
    @include media-breakpoint-down(lg) {
      height: 100%;
      flex-direction: column;

      .description-wrapper-modal {
        &-course {
          padding-top: 0 !important;
        }

        &__header {
          position: sticky !important;
        }
      }
    }    
  }

  &.is-bottom {
    @include media-breakpoint-down(lg) {
      top: unset !important;
      bottom: 0 !important;
      transform: translateX(-50%) !important;
      width: 100% !important;
    }   
  }

  @include media-breakpoint-down(lg) {
    width: calc(100% - 20px);
  }
}

.description-wrapper-modal {
  width: 82.3rem;
  background-color: white;
  border-radius: 0.8rem;
  padding-bottom: 2.4rem;

  @include media-breakpoint-down(lg) {
    width: 100%
  }

  &__header {
    padding: 2.1rem 0;
    border-bottom: 1px solid #cccccc;

    .content {
      display: flex;
      justify-content: space-between;
      padding: 0 3.5rem;

      font-weight: 700;

      .title {
        font-size: 2rem;
      }

      .quantity {
        font-size: 2rem;
        color: var(--color-primary-blue);
      }
    }
  }

  &__content {
    padding: 0 3.5rem;

    .price {
      padding-top: 1.3rem;

      @include media-breakpoint-down(lg) {
        text-align: center;
        font-size: 1.5rem;
        width: 100%;
      }
    }

    .title-2 {
      @include media-breakpoint-down(lg) {
        font-size: 2rem;
      }
    }

    .title-3 {
      font-size: 1.6rem;
      padding: 1.3rem 0 0.8rem 0;
    }

    .description {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 160%;

      @include media-breakpoint-down(lg) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }
    }
  }

  &__footer {
    padding: 0 3.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    button {
      @include media-breakpoint-down(lg) {
        flex-basis: 50%;
        padding: 0;
      }
    }
  }
}

.description-wrapper-modal-course {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.8rem;
  padding-bottom: 2.4rem;

  &__header {
    padding: 2.1rem 0;
    border-bottom: 1px solid #cccccc;

    .content {
      display: flex;
      justify-content: space-between;
      padding: 0 3.5rem;
      font-weight: 700;

      .title {
        font-size: 2rem;
      }

      .quantity {
        font-size: 2rem;
        color: var(--color-primary-blue);
      }
    }
  }

  &__content {
    padding: 0 3.5rem;

    .price {
      padding-top: 1.3rem;
    }

    .title-3 {
      font-size: 1.6rem;
      padding: 1.3rem 0 0.8rem 0;
    }

    .description {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 160%;
    }
  }

  &__footer {
    padding: 0 3.5rem;
    display: flex;
    justify-content: space-between;
    gap: 30rem;
  }
}

.description-inside-modal {
}
