.cart-pages {
  @include media-breakpoint-down(lg) {  
    min-height: 45vh;
  } 
}

.cart-wrapper {
  padding: 0rem 6rem 6rem 6rem;

  @include media-breakpoint-down(lg) {  
    padding: 0rem 2rem;
  }
}

.cart-title {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  color: #000000;

  @include media-breakpoint-down(lg) {  
    font-size: 2.5rem;
  }
}

.cart-checkbox {
  display: flex;

  @include media-breakpoint-down(lg) {  
    align-items: center;
  }

  .form-check-input {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.7rem;

    @include media-breakpoint-down(lg) {  
      margin-top: 0;
    }
  }

  .form-check-label {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    color: #000000;
    padding-left: 1rem;
    cursor: pointer;
    user-select: none;

    @include media-breakpoint-down(lg) {  
      font-size: 2rem;
    }
  }

  &-bold {
    @include media-breakpoint-down(lg) {  
      margin-bottom: 2rem;
    }

    .form-check-label {
      font-weight: 600;
      margin-bottom: 1rem;

      @include media-breakpoint-down(lg) {  
        margin-bottom: 0
      }
    }
  }
}

.cart-checkbox-inline {
  display: inline;
}

.cart-checkbox-container {
  position: relative;

  @include media-breakpoint-down(lg) {  
    margin-bottom: 5rem;
  }
}

.cart-delete-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  cursor: pointer;
}

.cart-delete-text {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  color: var(--color-danger);
  padding-left: 1rem;
  user-select: none;

  @include media-breakpoint-down(lg) {  
    font-size: 2rem;
  }
}

.cart-divider {
  border-top: 2px solid #f1f2f3;
  height: 5px !important;
  opacity: 0.07;
}

.cart-item {
  &-container {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
    border-radius: 1.2rem;
    padding: 2rem;
    
    &.purchased {
      opacity: 0.4;
      pointer-events: none;
    }

    @include media-breakpoint-down(lg) {  
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      margin-bottom: 2rem;
      align-items: stretch;
    }
  }

  &-img {
    // width: 12.5rem;
    aspect-ratio: 346/180;
    height: 12.5rem;
    border-radius: 1rem;
    margin-left: 1rem;
    overflow: hidden;

    @include media-breakpoint-down(lg) { 
      height: 6rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    position: relative;
    // width: 100%;
    flex-grow: 1;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }

  &-text {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    color: #000000;

    @include media-breakpoint-down(lg) { 
      font-size: 1.8rem;
    }
  }

  &-price-container {
    display: flex;
    margin-top: 5rem;

    @include media-breakpoint-down(lg) {  
      margin-top: 3rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-price {
    // position: absolute;
    // bottom: 1rem;
    // left: 2rem;
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    color: var(--color-primary-blue);

    @include media-breakpoint-down(lg) { 
      font-size: 1.8rem;
    }
  }

  &-sub {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-text-disable);
    margin-right: 1rem;
    cursor: pointer;
    user-select: none;
    display: inline-flex;
  }

  &-sub-active {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-primary-blue);
    margin-right: 1rem;
    cursor: pointer;
    user-select: none;
    display: inline-flex;
  }

  &-sub-divider {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-text-disable);
    opacity: 0.5;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  &-description {
    // position: absolute;
    // right: 1rem;
    // bottom: 1rem;
    display: inline-block;
    margin: auto 0 auto auto;
    margin-top: 1rem;
    text-wrap: nowrap;

    @include media-breakpoint-down(lg) {  
      margin: 0;
    }
    
  }

  &-desc-title-text {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @include media-breakpoint-down(lg) {  
      font-size: 1.5rem;
    }
  }

  &-desc-text {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #525252;
    padding-right: 2rem;

    @include media-breakpoint-down(lg) {  
      font-size: 1.5rem;
      padding-right: 0;
      
    }
  }
}

.cart-summary-container {
  @include media-breakpoint-down(lg) {  
    position: fixed;
    bottom: 0;
    z-index: 11;
    left: 0;
    right: 0;
    padding: 0;
  }
}

.cart-summary {
  position: sticky;
  top: 1rem;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
  border-radius: 1.2rem;

  @include media-breakpoint-down(lg) {  
    box-shadow: 0px -8px 12px rgba(173, 173, 173, 0.24);
    border-radius: 0;
  }

  &-table {
    width: 100%;
  }

  &-text {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    color: #151522;

    &-bold {
      font-weight: 600;
      font-size: 2.4rem;

      @include media-breakpoint-down(lg) {  
        font-size: 1.8rem;
      }
    }
  }

  &-right {
    float: right;
  }

  &-divider {
    border-top: 2px solid #f1f2f3;
  }
}

.cart-button {
  background: var(--color-primary-blue);
  border-radius: 0.4rem;
  border: 0;
  height: 5.8rem;
  width: 16rem;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  color: #ffffff;
  width: 100%;
  margin-top: 3rem;
}
.pointer {cursor: pointer;}
