.badgeBase{
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  color: rgb(180, 0, 255);
  line-height: 20px;
  border-radius:20px;
  padding: 2px 5px;

  &.medium{
    font-size: 20px;
  }

  &.darkBlue{
    background: rgba(0, 63, 165, 0.1);
    color: rgb(0, 63, 165)
  }

  &.lightBlue{
    background: rgba(6, 107, 251, 0.1);
    color: rgb(6, 107, 251)
  }

  &.pink{
    background: rgba(180, 0, 255, 0.1);
    color: rgb(180, 0, 255);
  }
}
