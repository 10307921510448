.button {
  padding: 1.6rem 3.2rem;
  width: 100%;
  margin-top: 1.75rem;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 400;
  border-radius: 0.3rem;
  border: 1px solid var(--color-primary-blue);
  color: var(--color-primary-blue);
}
.button-outline {
  border-radius: 0.3rem;
  border: 1px solid var(--color-primary-blue);
  background-color: white;
  white-space: nowrap;
}
.button-contained {
  color: white;
  background-color: var(--color-primary-blue);
  font-weight: 600;
}
.button-confirm {
  padding: 1rem 1.5rem;
  width: 50%;
  margin-top: 1.75rem;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 400;
  // border-radius: .3rem;
  // border: 1px solid var(--color-primary-blue);
  // color: var(--color-primary-blue);
  background-color: var(--color-primary-blue);
}
.button-contained-pink {
  background-color: var(--color-danger);
  border: none;
}

.button-disable {
  background-color: var(--color-neutral-300);
  color: white;
  font-weight: 600;
  opacity: 0.4;
  border: none;
}
