.bottomsheet {
  display: flex;
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(200%);
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.6s ease-in-out;
  z-index: 9999;
  justify-content: flex-end;
  align-items: flex-end;

  &-container {
    width: 100%;
    height: 75%;
    padding: 5rem 2rem;
    position: relative;
    transform: translateY(200%);
    transition: all 0.2s ease-in-out;
    overflow: auto;
    background: white;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  &.opened {
    transform: translateY(0);
    opacity: 1;

    .bottomsheet-container {
      transform: translateY(0);
    }
  }
}
