.description-recomended {
  grid-template-areas:
    'image-area details-area'
    'image-area button-area';

  @include media-breakpoint-down(lg) {
    width: calc(50% - 1rem);
    margin: 0;
    border-bottom: 0;
    border-radius: 1.2rem;
    padding: 2rem;
    grid-template-areas:
      'image-area image-area'
      'details-area details-area'
      'button-area button-area';
    box-shadow: 0 8px 12px rgba(173, 173, 173, 0.24);
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    grid-template-areas:
      'image-area details-area'
      'button-area button-area';
  }

  .course-image-container {
    width: 100%;
    aspect-ratio: 346/180;
    // aspect-ratio: 346/244;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    border-radius: 1rem;
    grid-area: image-area;

    @include media-breakpoint-down(lg) {
      aspect-ratio: 346/180;
      align-items: flex-start;
    }

    // @include media-breakpoint-down(md) {
    //   aspect-ratio: 149/120;
    //   align-items: flex-start;
    // }

    .course-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }
  }

  &__details {
    grid-column: 2/3;
    grid-area: details-area;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }

    .title-2 {
      font-size: 2rem;
    }

    .description {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.6rem;

      margin: 0 0 1rem 0;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      flex-grow: 1;
    }

    .informations {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      .author {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        font-size: 1.4rem;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);

        @include media-breakpoint-down(lg) {
          display: none;
        }

        @include media-breakpoint-down(md) {
          display: flex;
        }
      }
    }
  }

  .button-information {
    grid-area: button-area;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(lg) {
      padding: 1rem;
      height: auto;
      font-size: 1.8rem;
    }
  }
}

.description-checkout {
  position: sticky;
  bottom: 15rem;

  @include media-breakpoint-down(lg) {
    position: fixed;
    bottom: 0;
    top: unset;
    left: 0;
    right: 0;
    z-index: 9;
    border-radius: 0;
    box-shadow: 0px -8px 12px rgba(173, 173, 173, 0.24);
    padding: 1rem 3rem 3rem;
  }

  .image-container {
    // background-image: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0) 14.58%,
    //   rgba(56, 56, 56, 0.64) 100%
    // );
    border-radius: 1.2rem;
    width: 100%;
    aspect-ratio: 346/180;
    // aspect-ratio: 475/239;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: -1;
    }
  }

  &__details {
    margin: 1.6rem 0;
    padding-top: 1.2rem;
    border-top: 1px solid rgba(241, 242, 243, 1);

    .informations {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin: 1.2rem 0;

      @include media-breakpoint-down(lg) {
        justify-content: space-between;
      }

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
      }

      .light {
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.8rem;

        @include media-breakpoint-down(md) {
          font-size: 1.8rem;
        }
      }
      .bold {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.8rem;
        margin-left: 2%;

        @include media-breakpoint-down(md) {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.card-bundle {
  border-bottom: 1px solid #C4C4C4;
  padding: 24px 0 !important;

  .category-card-title {
    margin-top: 0;
  }

  &-wrapper {
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(173, 173, 173, 0.24);
    border-radius: 1.2rem;
    padding: 1.6rem;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-image-container {
    border-radius: 1.4rem;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 346/180;
    // aspect-ratio: 390/244;
    position: relative;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    object-fit: contain;
    height: 100%;

    &:hover {
      transform: scale(1.3);
      transform-origin: 50% 50%;
    }
  }

  &-category {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: #969696;
    margin-top: 1rem;
  }

  &-user-picture-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 100%;

    .user-picture-container {
      &.company-logo {
        width: 58px;
        aspect-ratio: 1/1;
        border-radius: 10%;
        overflow: hidden;
      }

      &.course-logo {
        width: 196px;
        aspect-ratio: 196/58;
        overflow: hidden;

        .card-bundle-user-picture {
          object-fit: contain;
          object-position: left;
        }
      }
    }
  }

  &-user-picture {
    width: 100%;
    height: 100%;
  }

  &-user-name {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: #666666;
    margin-bottom: 0;

    &.name-in-logo {
      margin-left: 10px;
    }
  }

  &-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #000000;
    margin-top: 1rem;
    flex-grow: 1;
  }

  &-desc {
    font-size: 1.5rem;
  }

  &-price {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    color: var(--color-primary-blue);
  }

  &-button {
    border: 1px solid #066bfb;
    background: #fff;
    width: 100%;
    height: 5rem;
    border-radius: 0.4rem;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #066bfb;
    text-align: center;
  }

  .category-card-user-name {
    font-weight: 300;
  }
}

.image-container {
  &.mobile {
    width: 100%;
    aspect-ratio: 346/180;
    // aspect-ratio: 708/239;

    // @include media-breakpoint-down(md) {
    //   aspect-ratio: 375/239;
    // }

    .image {
      object-fit: cover;
    }
  }
}
