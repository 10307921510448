// Page Setup
.course-description-breadcrumb {
  margin: 0 auto;
  padding: 5rem;

  @media only screen and (max-width: $bp-largest) {
    width: 100%;
  }
}
.course-description {
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 56% 40%;
  grid-column-gap: 6rem;

  padding: 0 5rem 4rem;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    padding: 3rem;
    display: inline-block;
  }

  @media only screen and (max-width: $bp-largest) {
    width: 100%;
  }

  .user-image {
    margin-bottom: 20px;
    height: fit-content;

    .user-image-container {
      overflow: hidden;
      width: 287px;
      aspect-ratio: 287/95;
      position: relative;

      @include media-breakpoint-down(lg) {
        width: 200px;
      }

      img {
        object-fit: contain;
        object-position: left;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__overview {
    grid-column: 1/2;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

    .title-1 {
      margin-bottom: 6rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 3rem;
        font-size: 2.5rem;
      }
    }

    .title-2 {
      margin-bottom: 2.4rem;

      @include media-breakpoint-down(lg) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
    & span {
      margin-bottom: 100rem;
    }

    .descriptions {
      @include media-breakpoint-down(lg) {
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba(241, 242, 243, 1);
        font-size: 1.8rem;
      }
    }
  }

  &__testimonial {
    grid-column: 1/2;
    margin: 6rem 0 0 0;

    .customer-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .customer-avatar-wrapper {
        display: flex;
        padding: 0 5%;
        width: 100%;
        justify-content: space-between;
        position: relative;

        .customer-chevron {
          padding: 1rem;
          z-index: 2;
          border-radius: 50%;
          background: white;
          box-shadow: 0px 4px 12px 0px #98989826;
          cursor: pointer;
          aspect-ratio: 1/1;
          position: absolute;
          top: calc(3.6rem + (28px));
          transform: translateY(-50%);

          @include media-breakpoint-down(lg) {
            top: calc(3.6rem + (22.4px));
          }

          &.right {
            right: 0;
          }

          &.left {
            left: 0;
          }
        }
      }

      .customer {
        display: grid;
        grid-auto-columns: max-content max-content;
        align-items: center;
        gap: 1.6rem;

        cursor: pointer;

        .details {
          grid-column: 2/3;
          max-width: 150px;
          margin-top: 3.6rem;

          @include media-breakpoint-down(md) {
            max-width: 80px;
          }

          .bold {
            font-weight: 700;
            font-size: 1.8rem;
            color: var(--color-primary-blue-dark);
          }
          .light {
            font-size: 1.4rem;
            color: var(--color-primary-blue-dark);
          }
        }
      }
    }
  }

  &__faq {
    margin: 6rem 0 0 0;
  }

  &__faq-items {
    .accordion___container {
      width: 100%;
      margin-bottom: 8px;
      line-height: 15px;
      border: 1px solid #ffffff;

      .accordion___title {
        width: 100%;
        position: relative;
        text-align: left;
        padding: 14.5px;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        background: #ffffff;
        border-radius: 5px;
      }

      .accordion___content {
        font-family: Titillium Web;
        font-size: 16px;
        padding: 20px 10px;
        width: 100%;
        position: relative;
        line-height: 30.42px;
        padding: 12px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 5px;
        margin-top: 10px;
        background: rgba(6, 107, 251, 0.05);
      }
    }
  }

  &__recomended {
    grid-column: 1/2;
    margin-top: 4rem;
    overflow-x: hidden;

    .show-more {
      text-align: center;
      font-weight: 600;
      font-size: 2rem;
      color: var(--color-primary-blue);
      cursor: pointer;
      margin: 20px 0;
    }

    .title-2 {
      margin-bottom: 1rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 3rem;
        margin-top: 1rem;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        margin-top: unset;
      }
    }

    .course-container-not-bundling {
      &.grid-display{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
      }


      @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
      }
    }
  }
  &__checkout {
    grid-column: 2 / 3;
    grid-row: 1 / -1;
  }
}

.course-bundle {
  &-container {
    padding: 0 4rem 4rem;
    margin: 0 auto;
    width: 130.8rem;

    @media only screen and (max-width: $bp-largest) {
      width: 100%;
      padding: 0 0 4rem 0;
    }
  }
}

.frequently-questions-section-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1rem;
}

.bundle-container {
  padding: 0 5rem 5rem;

  .course-description {
    padding: 0 0 4rem;
  }

  .course-description-breadcrumb {
    padding-left: 0;
    padding-right: 0;

    @media only screen and (max-width: $bp-largest) {
      padding: 16px;
      align-items: center;

      .breadcrumb-text {
        font-size: 12px;
      }

      .breadcrumb-arrow {
        height: 10px;
      }
    }
  }

  #back-button-container {
    display: flex;

    button {
      margin-left: auto;
      width: 160px;
    }
  }

  .badge-container {
    display: flex;
    align-items: start;
    justify-content: flex-end;
    flex: 1;
  }

  #about-description {
    margin-bottom: 6rem;
  }

  .mobile-preview-button {
    display: none;
  }

  @media only screen and (max-width: $bp-largest) {
    padding: 0 0 5rem;

    .course-description {
      padding: 0 16px 4rem;
    }

    .course-name-avatar {
      display: flex;
      flex-direction: column-reverse;

      .user-image {
        margin-bottom: 8px;
        margin-left: -16px;
        margin-top: 1px;

        .user-image-container img {
          height: auto !important;
        }
      }
    }

    .badge-container {
      justify-content: flex-start;

      .badgeBase {
        font-size: 10px;
        padding: 0 10px;
      }
    }

    #about-title {
      font-weight: 400;
    }

    #about-description {
      margin-bottom: 2rem;
    }

    .frequently-questions-section-title {
      font-size: 16px;
    }

    #back-button-container {
      width: 100%;
      padding: 0 16px;

      button {
        width: 100%;
        padding: 8px 16px;
        height: auto;
        font-size: 12px;
      }
    }

    .course-description__recomended {
      padding: 0 16px;
      margin-top: 0;

      .title-2 {
        font-size: 16px;
        font-weight: 400;
      }

      .course-container-not-bundling.grid-display {
        display: block;

        .landing-card-wrapper {
          margin-bottom: 8px;
        }
      }
    }

    .desktop-preview-button {
      display: none;
    }

    .mobile-preview-button {
      display: block;
      margin-top: 10px;
      margin-bottom: 24px;

      button {
        padding: 16px 32px 16px 32px;
        font-weight: 400;
        font-size: 18px;
        height: auto;
      }
    }

    .card-floating.description-checkout {
      padding: 16px;

      .button {
        padding: 16px 32px 16px 32px;
        font-weight: 400;
        font-size: 18px;
        height: auto;
        margin: 0;
      }
    }
  }
}
